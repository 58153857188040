html {
    background: #333;
}

@media (min-width: 470px) {
    body {
    }

    html {
        margin-top: 5rem;
    }
}

main {
    background: #333;
}

.m1 {
    margin: 1rem;
}

.global_wrap {
    max-width: 800px;
    min-height: 30vh;
    margin: 0 auto;
    scroll-behavior: smooth;
    border-radius: 0.5rem;
    width: 100%;
    place-content: center;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    scroll-snap-type: y mandatory;
}

.page_content_wrap {
    padding: 4rem 0;
    text-align: center;
    background: #fff;
    border-radius: 0.5rem;
}

body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    color: #333;
}

a {
    color: inherit;
    text-decoration: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    display: flex;
    align-items: center;
}

a img {
    margin-right: 1em;
}

.footer_sign_area {
    flex-wrap: wrap;
    color: #fff;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 0.5rem
}

.footer_sign_area a {
    padding: 0.5rem;
}

.usom_debugger {
    overflow: scroll;
    background: #333;
    color: #ddd;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 1rem;
    margin: 1rem;
}

.txt_small {
    font-size: 0.5rem;
    padding: 1rem;
}

.addressBox_wrap {
    display: grid;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
}

.addressBox {
    display: grid;
    grid-template-columns: 1fr 50px;
}

.btn_reload {
    width: fit-content;
    word-break: keep-all;
    border: 0;
    background: #6257f3;
    /*background: url("/icon/icons8/recycling-50.png") no-repeat 90% 90% / 30% 30%;*/
    padding: 0.5rem;
    border-radius: 0 1rem 1rem 0;
    color: #fff;
    font-size: 1.2rem;
    z-index: 1;
    display: block;
}

.btn_renew {
    border: 0;
    background: #6257f3;
    padding: 0.2rem 0;
    border-radius: 0rem 0rem 1rem 1rem;
    color: #fff;
    display: block;
    width: 3rem;
    margin-left: 1rem;
}

.copied {
    width: 250px;
    height: 100px;
    background: #3ee;
    margin: auto
}

.xejwiojhsidfja {
    padding-bottom: 1rem;
    border-bottom: 1px dotted #ddd;
    margin-bottom: 1rem;
    display: grid;
    gap: 0.5rem;
    grid-template-areas:
    'itemA . .'
    'itemB itemC itemD'
}
.grid-itemA {grid-area: itemA;
    padding: 0.5rem;
}
.grid-itemB {grid-area: itemB;
    display: inline-block;
    text-overflow: ellipsis;
    word-break: break-all;
    font-size: xx-small;
}
.grid-itemC {grid-area: itemC;
    font-size: smaller;
}
.grid-itemD {grid-area: itemD;
    font-size: xx-small;
    right: 1rem;
    bottom: 0;
}

.showMessageBody {
    word-break: break-all;
}

.container_head {
    min-height: 10rem;
    text-align: center;
    color: #ddd;
    margin: 0 auto;
    background: #333;
    padding: 3rem 1rem 1rem;
}

nav {
    align-items: center;
    display: grid;
    justify-content: space-between;
    text-align: right;
    gap: 0.2rem;
    grid-template-columns: 0fr 0fr;
    overflow: hidden;
}

.logo_wrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    margin-left: 1rem;
}

.logo {
    width: 1.5rem;
    height: 1.5rem;
    background: url(/logo.png) no-repeat 0 0 /100% 100%;
    border: 2px solid #0e76fd;
    outline: 2px solid;
}

.logo ~ button {
    font-size: 1.2rem;
    margin-left: 0.5rem;
}

.button_simple {
    margin: 1rem;
    border: solid #333;
    border-radius: 0.5rem;
    font-size: 1rem;
    background: #fff;
    box-shadow: 0 0 1px 1px;
}

.button_connectWallet {
    background: url(/metamask-fox.svg) transparent 0 0 no-repeat;
    width: 2.0rem;
    height: 2.0rem;
    border-radius: 1rem;
    filter: grayscale(0);
}

.button_connectWallet:disabled {
    filter: grayscale(1);
}

ul {
    display: flex;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

li {
    margin-right: 1rem;
    display: flex;
}

li:first-child {
    margin-left: auto;
}


header {
    padding: 0.2rem;
    color: #fff;
    background-color: #333;
    position: sticky;
    top: 0;
    z-index: 100;
}

.dispNone {
    display: none;
}

.mp2 {
    padding: 2rem;
    margin: 2rem;
}

.flex {
    align-items: center;
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.grid-center {
    display: grid;
    justify-content: center;
}

.input_address_wrap {
    width: 100%;
    position: relative;
}

.input_address {

    text-align: center;
    padding: 1rem;
    background: #fff2;
    border: 0;
    color: #ddd;
    border-radius: 1rem 0 0 1rem;
    font-size: 1.2rem;
    width: 100%;
}


.messageBoxHead {
    background: #333;
    border-radius: 0.5rem 0.5rem 0 0;
    color: #eee;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
    padding-left: 1rem;
    filter: drop-shadow(2px 4px 6px black);
}

.messageBox {
    filter: drop-shadow(2px 4px 6px black);
    min-height: 15rem;
    background: #fff;
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 1rem;
    color: #333
}

.messageHead {
    background: #fff;
    color: #333
}

.container {
    background: #333333;
    max-width: 65rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.headContent {
    align-items: center;
    height: 2rem;
    display: flex;
    padding: 0;
    justify-content: flex-start;
}

.headContent p:nth-child(1) {
    width: 150px;
}

.flexGrow1 {
    flex-grow: 1;
}